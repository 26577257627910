
<template>
  <v-app id="inspire">
  <div>
    <title>เมนูการตั้งค่า</title>
    <img src="../assets/image/UI Member PHOTO/Banner-08.png" class="img-fluid" alt="Responsive image" style="width: 100%;">

    <div style="width: 95%; margin: auto;">
    <div style="padding-bottom: 25px">
      <div style="padding-bottom: 20px">
          <div style="display: flex; padding-top: 10px;">
            <div style="width: 30%; text-align: center;">
              <img :src="picprofile" class="img-fluid " alt="Responsive image" style="width: 40px; height: 40px; border-radius: 50%;"/>
            </div>
            <div style="width: 70%;">
              <h1 style="font-size: 15px; margin: 0; color:#7155c6; ">{{textShowL1}}</h1>
              <h1 style="font-size: 15px; margin: 0; ">{{textShowL2}}</h1>
            </div>
          </div>
      </div>

      <div style="display: flex; justify-content: center;">
        <!-- <CButton color="secondary" style="margin: 0 5px;">
          <p style="margin: 0;">เปลี่ยนเบอร์มือถือ</p>
        </CButton> -->
        <!-- <CButton style="background-color:#382293; color:#FFFFFF; margin: 0 5px;">เปลี่ยนเบอร์มือถือ</CButton> -->
        <CButton v-on:click="Changeinfo()"  style="background-color:#7c52f4; color:#FFFFFF; margin: 0 5px;"><p style="margin: 0;">เปลี่ยนข้อมูลส่วนตัว</p></CButton>
      </div>

    </div>

    <!-- <div>
        <CCard style="background-color:#f3f3f3;">
          <CCardBody>
            <h1 style="font-size:16px;">การแจ้งเตือน เมื่อทำรายการเสร็จ</h1>
            <div style="display: flex; margin: 0 0 0 20px;">
              <div>
                <CInputCheckbox :checked="lineNoti" type="checkbox" @update:checked="checkBoxLineNoti($event)" />
              </div>
              <div >
                <label style="margin: 0; font-size:13px;">แจ้งผ่าน Line Notification</label>
              </div>
            </div>
            <div style="display: flex; margin: 0 0 0 20px;">
              <div>
                <CInputCheckbox :checked="SMSNoti" type="checkbox" @update:checked="checkBoxSMSNoti($event)" />
              </div>
              <div >
                <label style="margin: 0; font-size:13px;">แจ้งผ่าน SMS เข้าเบอร์มือถือของคุณ</label>
              </div>
            </div>
          </CCardBody>
        </CCard>
    </div> -->

    <!-- <div>
        <CCard style="background-color:#f3f3f3;">
          <CCardBody>
            <h1 style="font-size:16px;">การถามรหัสผ่าน Pin หน้าตู้คีออส</h1>
            <div style="display: flex;">
              <div style="display: flex; margin: 0 0 0 20px;">
                <div>
                  <CInputCheckbox :checked="askPinKiosk_ask" type="checkbox" @update:checked="AskPinKiosk_ask($event)" />
                </div>
                <div >
                  <label style="margin: 0; font-size:13px;">ถาม</label>
                </div>
              </div>
              <div style="display: flex; margin: 0 auto 0 auto;">
                <div>
                  <CInputCheckbox :checked="askPinKiosk_dontask" type="checkbox" @update:checked="AskPinKiosk_dontask($event)" />
                </div>
                <div >
                  <label style="margin: 0; font-size:13px;">ไม่ถาม</label>
                </div>
              </div>
            </div>

          </CCardBody>
        </CCard>
    </div> -->

    <div>
        <CCard style="background-color:#e5e5e5;">
          <CCardBody>
            <h1 style="font-size:16px;">ถามรหัส PIN เพื่อตรวจสอบยอดเงินคงเหลือ</h1>

            <div style="display: flex;">
              <div style="display: flex; margin: 0 0 0 20px;">
                <div>
                  <!-- <CInputCheckbox :checked="askPinLine_ask" type="checkbox" @update:checked="AskPinLine_ask($event)" /> -->
                  <input type="checkbox" id="askPinLine_ask" @click="AskPinLine_ask()">
                </div>
                <div >
                  <!-- <label style="margin: 0; font-size:13px;">ถาม</label> -->
                  <label for="html"><h2 style="font-size: 14px; margin: 0 0 0 10px;">ถาม</h2></label><br>
                </div>
              </div>

              <div style="display: flex; margin: 0 auto 0 auto;">
                <div>
                  <!-- <CInputCheckbox :checked="askPinLine_dontask" type="checkbox" @update:checked="AskPinLine_dontask($event)" /> -->
                  <input type="checkbox" id="askPinLine_dontask" @click="AskPinLine_dontask()">
                </div>
                <div >
                  <!-- <label style="margin: 0; font-size:13px;">ไม่ถาม</label> -->
                  <label for="html"><h2 style="font-size: 14px; margin: 0 0 0 10px;">ไม่ถาม</h2></label><br>
                </div>
              </div>
            </div>

          </CCardBody>
        </CCard>
    </div>

    
    <div style="display: flex; justify-content: center; margin: 0 0 50px 0;">
      <CButton v-on:click="Cancel()" style="background-color:#FF5C57; color:#FFFFFF; margin: 0 10px 0 10px; width: 30%;">
        <p style="margin: 0 10px;">ยกเลิก</p>
      </CButton>
      <CButton v-on:click="Conferim()" style="background-color:#1EC688; color:#FFFFFF; margin: 0 10px 0 10px; width: 30%;">
        <p style="margin: 0 10px;">ตกลง</p>
      </CButton>
    </div>

    <v-dialog
        v-model="warningModal"
        persistent
        width="100%"
      >
        <v-card>
          <div style="background-color:#7c52f4; height: 40px;">
            <h1 style="font-size: 18px; margin: 0 0 0 20px; padding-top: 8px; color:#FFF;">การตั้งค่า</h1>
          </div>
          <div style="padding: 10px;">
            <CCard >
              <CCardBody style="background-color:#e5e5e5;">
                <div class="d-flex" style="margin: 10px;">
                  <div style="width: 30%;">
                    <center>
                      <img src="../assets/image/UI Member PHOTO/Member icon-02.png" class="img-fluid" alt="Responsive image" style="width: 70%;"/>
                    </center>
                  </div>
                  <div style="width: 70%; display: flex;">
                    <h1  style="font-size:14px; text-align: center; margin: auto auto;">บันทึกการตั้งค่าสำเร็จ.</h1>
                  </div>
                </div>
              </CCardBody>
            </CCard>
            <center>
              <CButton v-on:click="closeCModal()" style="background-color:#1ec688; color:#FFFFFF; margin: 0 0px "><p style="margin: 0;">ยืนยัน</p></CButton>
            </center>
          </div>
        </v-card>
    </v-dialog>

    </div>
  </div>
  </v-app>
</template>

<script>
import MainChartExample from './charts/MainChartExample'
import WidgetsDropdown from './widgets/WidgetsDropdown'
import WidgetsBrand from './widgets/WidgetsBrand'
const axios = require("axios");
import { freeSet } from '@coreui/icons'
import config from '../config'



export default {
  name: 'Dashboard',
  components: {
    MainChartExample,
    WidgetsDropdown,
    WidgetsBrand
  },
  data () {
    return {
      warningModal: false,
      textShowL1:"",
      textShowL2:"",
      sex:"",
      nameUser:"",
      picprofile: require('@/assets/image/UI Member PHOTO/Member icon-02.png'),
      picprofile: "http://pandata.online/public/images/devices/WS_SPEEDQUEEN_10_5KG_ATEE9ASP455LW01.jpg",
      profile:"",
      branch:"",
      lineNoti:false,
      SMSNoti:false,
      askPinKiosk_ask:false,
      askPinKiosk_dontask:true,

      // backup
      askPinLine_ask:false,
      askPinLine_dontask:true,

      // askPinLine_ask:true,
      // askPinLine_dontask:false,




    }
  },
  mounted() {
    var listkey = Object.keys(this.$store.state.profile)   
    if(listkey.length <= 3){
      this.$router.push('/dashboard?pageww=setting')
    }

    // this.$store.state.popupregis = this.$store.state.popupregis_buff
    this.profile = this.$store.state.profile
 
    this.nameUser = this.$store.state.profile.nemeuser
    this.sex = this.$store.state.profile.sex
    this.branch = this.$store.state.profile.branch

    // this.askPinKiosk_ask = !!+this.profile.setting.AskPinKiosk
    // this.askPinKiosk_dontask  = !this.askPinKiosk_ask
    // this.askPinLine_ask = !!+this.profile.setting.AskPinLine
    // this.askPinLine_dontask = !this.askPinLine_ask
    // this.lineNoti = !!+this.profile.setting.LineNotification
    // this.SMSNoti = !!+this.profile.setting.SMSNotification

    document.getElementById("askPinLine_ask").checked = !!+this.profile.setting.AskPinLine;
    document.getElementById("askPinLine_dontask").checked = !+this.profile.setting.AskPinLine;

    console.log("-------",this.profile.setting)
    this.selectProfile(this.sex)

    
  },
  methods: {
    selectProfile(sex) {
      if(sex == "male"){
          // this.picprofile = require('@/assets/image/UI Member PHOTO/Member icon-03.png')
          this.picprofile = this.profile.pictureUrl
          this.textShowL1 = "สวัสดีครับ ,"
          this.textShowL2 = "คุณ " + this.nameUser
        }
        else if(sex == "female"){
          // this.picprofile = require('@/assets/image/UI Member PHOTO/Member icon-01.png')
          this.picprofile = this.profile.pictureUrl
          this.textShowL1 = "สวัสดีค่ะ ,"
          this.textShowL2 = "คุณ " + this.nameUser
        }
        else if(sex == "not specified" || sex == "not specif"){
          // this.picprofile = require('@/assets/image/UI Member PHOTO/Member icon-01.png')
          this.picprofile = this.profile.pictureUrl
          this.textShowL1 = "สวัสดีค่ะ ,"
          this.textShowL2 = "คุณ " + this.nameUser
        }
    },
    checkBoxLineNoti(e){
      this.lineNoti = e
      console.log(this.lineNoti)
    },
    checkBoxSMSNoti(e){
      this.SMSNoti = e
      console.log(this.SMSNoti)
    },
    AskPinLine_ask(){
      var e = document.getElementById("askPinLine_ask").checked;
      this.askPinLine_ask = e
      this.askPinLine_dontask = !e
      document.getElementById("askPinLine_dontask").checked = this.askPinLine_dontask;
    },
    AskPinLine_dontask(){
      var e = document.getElementById("askPinLine_dontask").checked;
      this.askPinLine_ask = !e
      this.askPinLine_dontask = e
      document.getElementById("askPinLine_ask").checked = this.askPinLine_ask;
    },
    AskPinKiosk_ask(e){
      this.askPinKiosk_ask = e
      this.askPinKiosk_dontask = !e
      console.log("ask",this.askPinKiosk_ask,this.askPinKiosk_dontask)
    },
    AskPinKiosk_dontask(e){
      this.askPinKiosk_ask = !e
      this.askPinKiosk_dontask = e
      console.log("dontask",this.askPinKiosk_ask,this.askPinKiosk_dontask)
    },
    Conferim(){
      let data = {
          "userId": this.profile.userId,
          "linenoti": this.lineNoti,
          "smsnoti": this.SMSNoti,
          "askpinline": this.askPinLine_ask,
          "askpinkiosk": this.askPinKiosk_ask
      }
      this.API_UpdateSetting(data).then((res) => {
        if(res.data.success){
          this.warningModal = true
          // console.log("UPDATED!")
          // alert("บันทึการตั้งค่าของคุณสำเร็จ.")
          this.profile.setting.AskPinKiosk = this.askPinKiosk_ask
          this.profile.setting.AskPinLine = this.askPinLine_ask
          this.profile.setting.LineNotification = this.lineNoti
          this.profile.setting.SMSNotification = this.SMSNoti
          // this.$router.push('/memberww')
        }
        else{

        }
      });

    },
    Cancel(){
      this.$router.push('/memberww')
    },
    Changeinfo(){
      console.log('/changeinfoww')
      this.$router.push('/changeinfoww')
    },
    closeCModal(){
      this.warningModal = false
      this.$router.push('/memberww')
    },
    API_UpdateSetting(data) {
      return new Promise((resolve, reject) => {
        const header = { "Content-Type": "application/json" };
        axios
          .post(config.server+"/setting", data, {
            headers: header,
          })
          .then(function (res) {
            resolve(res);
          })
          .catch(function (err) {
            reject(err);
          });
      });
    }
  }
}
</script>
